












































import {Component, Emit, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import {VMoney, Money} from 'v-money';

@Component({
    components: {
        Money
    }
})
export default class TextEditable extends Vue {
    @Prop({default: true}) allowEdit!: boolean;
    @Prop({default: false}) isMultiple!: boolean;
    @Prop({default: ''}) placeholder!: boolean;
    @Prop({default: ''}) value!: any;
    @Prop({default: 'text'}) type!: string; // Type: text | money.
    @Prop({default: 'Chưa có giá trị'}) noValueText!: any;
    @Prop({
        default: () => {
            return {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                precision: 0,
                masked: false
            }
        }
    }) moneyOption!: any;

    @Ref('inputElement') inputElement!: any;
    isEdit = false;

    currentValue: any;

    @Watch('isEdit')
    onIsEditChange(value: boolean) {
        if (value) {
            setTimeout(() => {
                if (this.type === 'text') {
                    this.inputElement.focus();
                } else {
                    this.inputElement.$el.focus()
                }
            }, 200);
        }
    }

    @Watch('value')
    onValueChange(value: boolean) {
        this.$emit('input', value);
    }

    @Emit('acceptChange')
    emitAcceptChange() {
        this.isEdit = false;
        this.$emit('input', this.currentValue);
        return this.currentValue;
    }

    @Emit('cancel')
    onCancel() {
        this.isEdit = false;
        return;
    }

    get valueText() {
        if (!this.isEdit && this.value && this.type === 'money') {
            return this.formatPrice(this.value);
        }
        return this.value ? this.value : this.noValueText;
    }

    onEnter(e: any) {
        this.isEdit = false;
        this.emitAcceptChange();
    }

    onChangeValue(event: any) {
        if (this.type === 'money') {
            this.currentValue = event;
        } else {
            this.currentValue = event.target.value;
        }
    }

    edit() {
        if (!this.allowEdit) {
            return;
        }
        this.isEdit = true;
        this.currentValue = this.value;
    }

    close() {
        this.isEdit = false;
    }

    private formatPrice(value: number) {
        let val = (value / 1).toFixed(this.moneyOption.precision).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
}
