import {CustomAttributeResult} from '@/models/attribute/CustomAttributeResult';
import {CustomerAddressRoute} from "@/models/attribute/CustomerAddressRoute";

export class CustomerAddress {
    id: string;
    type: number | undefined;
    provinceId: string | undefined;
    provinceName: string;
    districtId?: string;
    districtName: string;
    // wards: string | undefined;
    street: string | undefined;
    location: string | undefined;
    isPrimary: boolean;
    customAttributes?: Array<CustomAttributeResult>;
    routes: Array<CustomerAddressRoute>;
    address: string;
    addressDisplay: string;

    constructor(
        type?: number,
        provinceId?: string,
        districtId?: string,
        // wards?: string,
        street?: string,
        location?: string
    ) {
        this.id = '';
        this.type = type;
        this.provinceId = provinceId;
        this.provinceName = '';
        this.districtId = districtId ? districtId : '';
        this.districtName = '';
        // this.wards = wards ? wards : '';
        this.street = street;
        this.location = location;
        this.isPrimary = false;
        this.address = '';
        this.customAttributes = [];
        this.routes = [];
        this.addressDisplay = '';
    }
}
