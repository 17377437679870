













































































import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import {CustomerAddress} from '@/models/customer/CustomerAddress';
import * as _ from 'lodash';
import {BankAccount} from "@/models/bank/BankAccount";
import CustomerAddressService from "@/views/customer/address/CustomerAddressService";
import {ActionResult} from "@/models/ActionResult";

@Component({
    components: {
        CustomerAddressForm: () => import(/* webpackChunkName: "CustomerAddressForm" */ '@/views/customer/address/CustomerAddressForm.vue')
    }
})
export default class CustomerAddressList extends Vue {
    @Prop({default: null}) customerId?: string;
    @Prop({default: () => []}) listItem?: CustomerAddress[];
    @Prop({default: false}) isEdit?: boolean;
    @Ref('customerAddressForm')
    customerAddressForm!: any;

    isUpdate = false;
    address = new CustomerAddress();
    listAddressType = [];
    listProvince = [];
    selectedAddress?: CustomerAddress = undefined;
    customerAddressService = new CustomerAddressService();

    onModalShown() {
    }

    private _listAddress = [];

    get modalTitle() {
        return this.isUpdate
            ? this.$t('title.titleUpdate')
            : this.$t('title.addNew');
    }

    get listAddress() {
        return this.listItem;
    }

    set listAddress(value: any) {
        this._listAddress = value;
    }

    onAdded(customerAddress: CustomerAddress) {
        if (customerAddress.isPrimary) {
            _.each(this.listAddress, (address: CustomerAddress) => {
                address.isPrimary = customerAddress.id === address.id;
            });
        }
        this.listAddress = this.listAddress.push(customerAddress);
    }

    onUpdated(customerAddress: CustomerAddress) {
        if (customerAddress.isPrimary) {
            _.each(this.listAddress, (address: CustomerAddress) => {
                address.isPrimary = customerAddress.id === address.id;
            });
        }

        const customerAddressInfo: CustomerAddress = _.find(this.listAddress, (item: CustomerAddress) => {
            return customerAddress.id === item.id;
        });

        if (customerAddressInfo) {
            customerAddressInfo.provinceId = customerAddress.provinceId;
            customerAddressInfo.provinceName = customerAddress.provinceName;
            customerAddressInfo.districtId = customerAddress.districtId;
            customerAddressInfo.districtName = customerAddress.districtName;
            customerAddressInfo.isPrimary = customerAddress.isPrimary;
            // customerAddressInfo.wards = customerAddress.wards;
            customerAddressInfo.customAttributes = customerAddress.customAttributes;
        }
    }

    addBank() {
        this.isUpdate = false;
        this.customerAddressForm.add();
    }

    showDetail(customerAddress: CustomerAddress) {
        this.customerAddressForm.showDetail(customerAddress);
    }

    edit(customerAddress: CustomerAddress) {
        this.isUpdate = true;
        this.customerAddressForm.edit(_.cloneDeep(customerAddress));
    }

    remove(customerAddress: CustomerAddress) {
        const bankAccountByLanguage: any = this.$t('Customer address');
        this.selectedAddress = customerAddress;
        this.$vs.dialog({
            type: 'confirm',
            color: 'danger',
            title: this.$t('Confirm delete'),
            acceptText: this.$t('Accept'),
            cancelText: this.$t('Cancel'),
            text: this.$t('ConfirmDeleteMessage', [bankAccountByLanguage.toLowerCase(), `${customerAddress.provinceName}/${customerAddress.districtName}`]),
            accept: this.confirmRemove
        });
    }

    confirmRemove() {
        if (this.selectedAddress && this.customerId) {
            this.customerAddressService.delete(this.customerId, this.selectedAddress.id)
                .then((result: ActionResult) => {
                    if (result.code > 0) {
                        const index = _.findIndex(this.listAddress, this.selectedAddress);
                        if (index != -1) {
                            this.$delete(this.listAddress, index);
                        }
                    }
                })
        }
    }
}
