





















































































import { Component, Vue, Prop, Ref, Emit } from "vue-property-decorator";
import { FileUploadType } from "@/components/upload-file/FileUploadType";
import axios from 'axios';
import { ActionResult } from "@/models/ActionResult";
import { Getter } from "vuex-class";
import { FileResult } from "@/components/upload-file/FileResult";

@Component
export default class UploadFileButton extends Vue {
    @Prop({default: FileUploadType.icon}) type!: FileUploadType;
    @Prop({default: false}) isMultiple!: boolean;
    @Prop({default: 'Choose a file'}) text!: boolean;

    @Getter("oidcAccessToken") accessToken!: string;

    @Ref('fileInput') fileInput!: any;

    fileUploadType = FileUploadType;
    files: any = [];

    @Emit('fileChanged')
    emitFileChanged() {
        return this.files;
    }

    @Emit('uploaded')
    emitUploaded(data: FileResult[]) {
        return data;
    }

    onFileChange(e: any) {
        this.files = e.target.files;
        if (this.files.length <= 0)
            return;
        this.handlerUploadFile();
    }

    private async handlerUploadFile() {
        const formData = new FormData();
        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `bearer ${this.accessToken}`
        };

        const url = `${process.env.VUE_APP_GATEWAY_URL}file/api/v1/uploads`;
        this.$vs.loading();

        if (!this.isMultiple) {
            formData.append('file', this.files[0]);

            try {
                const result: ActionResult<FileResult> = await axios.post(url, formData, {
                    headers
                });

                this.$vs.loading.close();
                const data = result.data as FileResult;
                this.emitUploaded([data]);
            } catch (e) {
                this.$vs.loading.close();
                console.log(e.message);
            }
        } else {
            try {
                for (let i = 0; i < this.files.length; i++) {
                    const file = this.files[i];
                    formData.append('files', file, file.name);
                }

                const result: ActionResult<FileResult>[] = await axios.post(`${url}/multiples`, formData, {
                    headers
                });

                this.$vs.loading.close();

                const datas = result.map((item: ActionResult<FileResult>) => {
                    return item.data;
                }) as FileResult[];
                this.emitUploaded(datas);
            } catch (e) {
                this.$vs.loading.close();
                console.log(e);
            }
        }
    }
}
