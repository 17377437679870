export class CustomerSuggestionModel {
    id: string;
    code: string;
    name: string;
    phoneNumber?: string;
    email?: string;
    resourceId?: string;
    agencyId?: string;
    classifyId?: string;
    potentialLevelId?: string;
    clarificationId?: string;
    strategyId?: string;
    focusGroupId?: string;
    businessCareerId?: string;
    isSelected: boolean;
    image?: string;
    address: any;
    roleId?: string;
    roleName?: string;
    erpCode?: string;

    constructor(id?: string, name?: string, isSelected?: boolean, resourceId?: string, agencyId?: string, classifyId?: string, potentialLevelId?: string, clarificationId?: string,
                strategyId?: string, focusGroupId?: string, businessCareerId?: string, image?: string, erpCode?: string) {
        this.id = id ? id : '';
        this.name = name ? name : '';
        this.resourceId = resourceId;
        this.agencyId = agencyId;
        this.classifyId = classifyId;
        this.potentialLevelId = potentialLevelId;
        this.clarificationId = clarificationId;
        this.strategyId = strategyId;
        this.focusGroupId = focusGroupId;
        this.businessCareerId = businessCareerId;
        this.isSelected = isSelected ? isSelected : false;
        this.image = image;
        this.phoneNumber = '';
        this.email = '';
        this.roleId = '';
        this.roleName = '';
        this.erpCode = erpCode;
    }
}
