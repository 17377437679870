export enum Permission {
    View = 1,
    Insert = 2,
    Update = 4,
    Delete = 8,
    Export = 16,
    Print = 32,
    Approve = 64,
    Report = 128,
    Full = 255
}