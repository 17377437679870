























































import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import {Contact} from '@/models/contact/Contact';
import {namespace} from 'vuex-class';
import CustomerSuggestion from "@/components/suggestion/customer-suggestion/CustomerSuggestion.vue";
import {CustomerSuggestionModel} from "@/components/suggestion/customer-suggestion/CustomerSuggestionModel";
import {CustomerService} from "@/services/customer/CustomerService";
import {CustomerContact} from "@/models/customer/CustomerContact";
import {SuggestionModel} from "@/components/suggestion/base-suggestion/SuggestionModel";

const contactStoreModule = namespace('contact');

@Component({
    components: {
        CustomerSuggestion,
        CustomerContactForm: () => import(/* webpackChunkName: "ContactForm" */ '@/views/customer/contact/CustomerContactForm.vue')
    }
})
export default class CustomerContactList extends Vue {
    @Prop({default: []}) value!: CustomerContact[];
    @Prop() customerId!: string;
    @Prop({default: false}) isEdit!: boolean;
    @Ref('contactFormComponent') contactFormComponent!: any;
    @Ref('customerSuggestion') customerSuggestion!: any;
    @Ref('customerContactForm') customerContactForm!: any;
    @contactStoreModule.State('newContactAdded') contactAddedState!: Contact;

    // listContact: CustomerContact[] = [];
    selectedContact: CustomerContact;

    @Watch('contactAddedState')
    onContactStateChange(value: any) {
        //console.log(value);
    }

    get listContact() {
        return this.value
            .map((contact: CustomerContact) => {
                contact.image = contact.image ? `${process.env.VUE_APP_GATEWAY_URL}files/${contact.image}`
                    : '';
                return contact;
            });
    }

    set listContact(value: CustomerContact[]) {
        this.$emit('input', value);
    }

    get listSelected() {
        return this.listContact.map((customerContact: CustomerContact) => {
            const customerSuggestion = new CustomerSuggestionModel(customerContact.id, customerContact.name);
            customerSuggestion.phoneNumber = customerContact.phoneNumber;
            customerSuggestion.email = customerContact.email;
            return new SuggestionModel(customerContact.id, customerContact.name, customerContact.phoneNumber, customerContact.image, false, customerSuggestion);
        })
    }

    onContactUpdated(contact: CustomerContact) {
        const existingContact = this.listContact.find((item: CustomerContact) => {
            return item.id === contact.id;
        });
        if (existingContact) {
            existingContact.name = contact.name;
            existingContact.roleId = contact.roleId;
            existingContact.roleName = contact.roleName;
            existingContact.phoneNumber = contact.phoneNumber;
            existingContact.email = contact.email;
            existingContact.image = contact.image;
        }
    }

    add() {
        this.customerSuggestion.show();
    }

    sendMail(customerContact: CustomerContact) {
        window.location.href = `mailto:${customerContact.email}`;
    }

    call(customerContact: CustomerContact) {
        window.location.href = `tel:${customerContact.phoneNumber}`;
    }

    edit(contact: CustomerContact) {
        this.customerContactForm.edit(contact);
    }

    confirmRemove(customerContact: CustomerContact) {
        this.selectedContact = customerContact;
        const confirmMessage = this.$t('ConfirmDeleteContactMessage', [customerContact.name]);
        this.$vs.dialog({
            type: 'confirm',
            color: 'danger',
            title: this.$t('Confirm delete'),
            acceptText: this.$t('Accept'),
            cancelText: this.$t('Cancel'),
            text: confirmMessage,
            accept: this.acceptRemove
        });
    }

    private async acceptRemove() {
        const customerService = new CustomerService();
        try {
            const result = await customerService.removeContact(this.customerId, this.selectedContact.id);
            this.listContact = this.listContact.filter((contact: CustomerContact) => {
                return contact.id !== this.selectedContact.id;
            })
        } catch (e) {
            this.$vs.notify({
                title: '',
                text: e.message,
                color: 'danger'
            });
        }
    }

    async onAccepted(selectedCustomers: SuggestionModel<CustomerSuggestionModel>[]) {
        const customerService = new CustomerService();
        try {
            const listCustomerContact: CustomerContact[] = selectedCustomers.map((suggestion: SuggestionModel<CustomerSuggestionModel>) => {
                const customer = suggestion.data as CustomerSuggestionModel;
                return {
                    id: customer.id,
                    name: customer.name,
                    email: customer.email,
                    phoneNumber: customer.phoneNumber,
                    image: customer.image,
                    roleId: customer.roleId,
                    roleName: customer.roleName
                } as CustomerContact
            });
            const result = await customerService.saveContact(this.customerId, listCustomerContact);
            this.listContact = listCustomerContact;
        } catch (e) {
            this.$vs.notify({
                title: '',
                text: e.message,
                color: 'danger'
            });
        }
    }
}
