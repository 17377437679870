























































import {Component, Prop, Vue} from "vue-property-decorator";
import {Permission} from "@/constants/Permission";
import * as _ from 'lodash';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

@Component({
    components: {
        VuePerfectScrollbar
    }
})
export default class EditPermissionModal extends Vue {
    isShow = false;
    permission = 0;
    settings = {
        maxScrollbarLength: 60,
        wheelSpeed: .60,
    };

    get popupTitle() {
        return this.$t('Change permission');
    }

    get isView() {
        return (this.permission & Permission.View) === Permission.View;
    }

    get isInsert() {
        return (this.permission & Permission.Insert) === Permission.Insert;
    }

    get isUpdate() {
        return (this.permission & Permission.Update) === Permission.Update;
    }

    get isDelete() {
        return (this.permission & Permission.Delete) === Permission.Delete;
    }

    get isApprove() {
        return (this.permission & Permission.Approve) === Permission.Approve;
    }

    get isExport() {
        return (this.permission & Permission.Export) === Permission.Export;
    }

    get isReport() {
        return (this.permission & Permission.Report) === Permission.Report;
    }

    get isPrint() {
        return (this.permission & Permission.Print) === Permission.Print;
    }

    get isFull() {
        return (this.permission & Permission.Full) === Permission.Full;
    }

    set isView(value: boolean) {
        this.permission = value ? this.permission + Permission.View : this.permission - Permission.View;
    }

    set isInsert(value: boolean) {
        this.permission = value ? this.permission + Permission.Insert : this.permission - Permission.Insert;
    }

    set isUpdate(value: boolean) {
        this.permission = value ? this.permission + Permission.Update : this.permission - Permission.Update;
    }

    set isDelete(value: boolean) {
        this.permission = value ? this.permission + Permission.Delete : this.permission - Permission.Delete;
    }

    set isApprove(value: boolean) {
        this.permission = value ? this.permission + Permission.Approve : this.permission - Permission.Approve;
    }

    set isExport(value: boolean) {
        this.permission = value ? this.permission + Permission.Export : this.permission - Permission.Export;
    }

    set isReport(value: boolean) {
        this.permission = value ? this.permission + Permission.Report : this.permission - Permission.Report;
    }

    set isPrint(value: boolean) {
        this.permission = value ? this.permission + Permission.Print : this.permission - Permission.Print;
    }

    set isFull(value: boolean) {
        this.permission = value ? Permission.Full : 0;
    }

    show(permission: number) {
        this.isShow = true;
        this.permission = _.cloneDeep(permission);
    }

    accept() {
        this.$emit('accepted', this.permission);
        this.isShow = false;
    }
}
