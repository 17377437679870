import {Permission} from '@/constants/Permission';
import i18n from "@/i18n";

export class UserShareModel {
    id: string;
    fullName: string;
    userName: string;
    avatar: string | undefined;
    _isView: boolean = true;
    _isInsert: boolean = false;
    _isUpdate: boolean = false;
    _isDelete: boolean = false;
    _isExport: boolean = false;
    _isPrint: boolean = false;
    _isApprove: boolean = false;
    _isReport: boolean = false;
    _isFull: boolean = false;
    _permission: number;

    constructor(id?: string, fullName?: string, userName?: string, permission?: Permission, avatar?: string) {
        this.id = id ? id : '';
        this.fullName = fullName ? fullName : '';
        this.userName = userName ? userName : '';
        this.avatar = avatar;
        this._permission = permission ? permission : 0;
    }

    get isView() {
        return this.isHasPermission(Permission.View);
    }

    get isInsert() {
        return this.isHasPermission(Permission.Insert);
    }

    get isUpdate() {
        return this.isHasPermission(Permission.Update);
    }

    get isDelete() {
        return this.isHasPermission(Permission.Delete);
    }

    get isExport() {
        return this.isHasPermission(Permission.Export);
    }

    get isPrint() {
        return this.isHasPermission(Permission.Print);
    }

    get isApprove() {
        return this.isHasPermission(Permission.Approve);
    }

    get isReport() {
        return this.isHasPermission(Permission.Report);
    }

    get isFull() {
        return this.isHasPermission(Permission.Full);
    }

    get description() {
        let permission = '';
        if (this.isFull) {
            return i18n.t('FullPermission');
        } else {
            if (this.isView) {
                permission += i18n.t('View');
            }
            if (this.isInsert) {
                permission += `${this.getCommas()}${i18n.t('Insert')}`;
            }
            if (this.isUpdate) {
                permission += `${this.getCommas()}${i18n.t('Update')}`;
            }
            if (this.isDelete) {
                permission += `${this.getCommas()}${i18n.t('Delete')}`;
            }
            if (this.isExport) {
                permission += `${this.getCommas()}${i18n.t('Export')}`;
            }
            if (this.isPrint) {
                permission += `${this.getCommas()}${i18n.t('Print')}`;
            }
            if (this.isApprove) {
                permission += `${this.getCommas()}${i18n.t('Approve')}`;
            }
            if (this.isReport) {
                permission += `${this.getCommas()}${i18n.t('Report')}`;
            }
        }

        return permission;
    }

    get permission() {
        return this._permission;
    }

    set permission(value: number) {
        this._permission = value;
    }

    private isHasPermission(hasPermission: Permission = Permission.View) {
        if (!this.permission) {
            return false;
        }
        return (this.permission & hasPermission) === hasPermission;
    }

    private getCommas() {
        return this.permission > 0 ? ', ' : '';
    }
}