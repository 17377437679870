








































import {Component, Emit, Prop, Ref, Vue} from 'vue-property-decorator';
import UserSuggestion from '@/components/suggestion/user-suggestion/UserSuggestion.vue';
import {UserSuggestionModel} from "@/components/suggestion/user-suggestion/UserSuggestionModel";
import {UserShareModel} from "@/components/user-share/UserShareModel";
import {Permission} from "@/constants/Permission";
import EditPermissionModal from "@/components/EditPermissionModal.vue";
import {SuggestionModel} from "@/components/suggestion/base-suggestion/SuggestionModel";

@Component({
    components: {
        UserSuggestion,
        EditPermissionModal
    }
})
export default class UserShareList extends Vue {
    @Prop({default: ''}) title!: string;
    @Prop({default: ''}) searchPlaceholder!: string;
    @Prop({default: false}) isEdit!: boolean;
    @Prop({default: () => []}) data!: UserShareModel[];
    @Ref('userSuggestion') userSuggestion!: UserSuggestion;
    @Ref('editPermissionModalComponent') editPermissionModalComponent!: any;
    keyword: string = '';
    user: any = {};
    debounceTime: any;
    selectedUserShare?: UserShareModel;

    @Emit('permissionChanged')
    onUserPermissionChanged(id: string, permission: number) {
        return {
            id, permission
        };
    }

    @Emit('removed')
    emitUserShareRemoved(id: string) {
        return id;
    }

    get listSelectedUser() {
        return this.data.map((userShare: UserShareModel) => {
            return new SuggestionModel(userShare.id, userShare.fullName, userShare.userName, userShare.avatar, true, userShare);
        });
    }

    onPermissionAccepted(permission: number) {
        if (this.selectedUserShare) {
            this.$emit('permissionChanged', {
                id: this.selectedUserShare.id,
                permission: permission
            });
        }
    }

    editPermission(item: UserShareModel) {
        this.selectedUserShare = item;
        this.editPermissionModalComponent.show(item.permission);
    }

    onUserSuggestionAccepted(suggestions: SuggestionModel[]) {
        const listShare = suggestions.map((suggestion: SuggestionModel<UserShareModel>) => {
            const userShare = suggestion.data;
            return userShare
                ? new UserShareModel(userShare.id, userShare.fullName, userShare.userName,
                    userShare.permission == null || userShare.permission === undefined ? Permission.View : userShare.permission, userShare.avatar)
                : {};
        });
        this.$emit('userAdded', listShare);
    }

    confirmRemove(userShare: UserShareModel) {
        this.selectedUserShare = userShare;
        const confirmMessage = this.$t('confirmRemoveMessage');
        this.$vs.dialog({
            type: 'confirm',
            color: 'danger',
            title: this.$t('Confirm delete'),
            acceptText: this.$t('Accept'),
            cancelText: this.$t('Cancel'),
            text: confirmMessage,
            accept: this.acceptRemove
        });
    }

    private acceptRemove() {
        if (this.selectedUserShare) {
            this.emitUserShareRemoved(this.selectedUserShare.id);
        }
    }
}
