import Vue from 'vue';
import Component from 'vue-class-component';
import axios from 'axios';
import {SearchResult} from '@/models/SearchResult';
import {AttributeResult} from '@/models/attribute/AttributeResult';
import {ActionResult} from "@/models/ActionResult";
import {CustomerAddress} from "@/models/customer/CustomerAddress";

@Component
export default class CustomerAddressService extends Vue {
    url = `${process.env.VUE_APP_CUSTOMER_URL}api/v1/customers`;

    insert(customerId: string, customerAddress: CustomerAddress): Promise<ActionResult<CustomerAddress>> {
        return axios.post(`${this.url}/${customerId}/addresses`, customerAddress);
    }

    update(customerId: string, id: string, customerAddress: CustomerAddress): Promise<ActionResult<CustomerAddress>> {
        return axios.put(`${this.url}/${customerId}/addresses/${id}`, customerAddress);
    }

    delete(customerId: string, id: string): Promise<ActionResult> {
        return axios.delete(`${this.url}/${customerId}/addresses/${id}`);
    }

    getDefaultAttribute(): Promise<SearchResult<AttributeResult>> {
        return axios.get(`${this.url}/addresses/attributes`);
    }

    updateDeliveryAddress(customerId: string, id: string): Promise<ActionResult>{
        return axios.put(`${this.url}/${customerId}/addresses/${id}/delivery-address`);
    }
}
