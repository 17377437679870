



























































import {Component, Emit, Prop, Ref, Vue} from 'vue-property-decorator';
import BankService from '@/views/customer/bank/BankService';
import {BankAccount} from '@/models/bank/BankAccount';

@Component({
    components: {
        BankForm: () => import(/* webpackChunkName: "BankForm" */ '@/views/customer/bank/BankForm.vue')
    }
})
export default class CustomerBankList extends Vue {
    @Prop({default: null}) customerId?: number;
    @Prop({default: false}) isEdit?: boolean;
    @Prop({default: () => []}) listItem!: BankAccount[];
    name: string = '';
    branch: string = '';
    bankingNumber: string = '';
    selectedBankAccount?: BankAccount;
    bankService: BankService;

    @Ref('bankFormComponent') bankFormComponent!: any;

    constructor() {
        super();
        this.bankService = new BankService();
    }

    mounted() {
    }

    @Emit('onBankAdded')
    onBankAdded(bankAccount: BankAccount) {
        return bankAccount;
    }

    @Emit('onBankUpdated')
    onBankUpdated(bankAccount: BankAccount) {
        return bankAccount;
    }

    @Emit('onRemove')
    onRemove(bankAccount?: BankAccount) {
        return bankAccount ? bankAccount.id : '';
    }

    onModalShown() {
        const bankNameInputElement: any = this.$refs.bankNameInput;
        bankNameInputElement.focus();
    }

    addBank() {
        this.bankFormComponent.add();
    }

    edit(bankAccount: BankAccount) {
        this.bankFormComponent.edit(bankAccount);
    }

    remove(bankAccount: BankAccount) {
        const bankAccountByLanguage: any = this.$t('Bank account');
        this.selectedBankAccount = bankAccount;
        this.$vs.dialog({
            type: 'confirm',
            color: 'danger',
            title: this.$t('Confirm delete'),
            acceptText: this.$t('Accept'),
            cancelText: this.$t('Cancel'),
            text: this.$t('ConfirmDeleteMessage', [bankAccountByLanguage.toLowerCase(), bankAccount.accountNumber]),
            accept: this.confirmRemove
        });
    }

    confirmRemove() {
        this.onRemove(this.selectedBankAccount as BankAccount);
    }
}
