export class Share {
    id: string;
    fullName: string;
    userName: string;
    avatar?: string;
    permission: number;

    constructor(id: string, fullName: string, userName: string, permission: number, avatar?: string) {
        this.id = id;
        this.fullName = fullName;
        this.userName = userName;
        this.permission = permission;
        this.avatar = avatar;
    }
}