


























import {Component, Emit, Prop, Ref, Vue} from 'vue-property-decorator';

@Component
export default class TextSelectEditable extends Vue {
    @Prop({default: true}) allowEdit!: boolean;
    @Prop({default: false}) isMultiple!: boolean;
    @Prop({default: ''}) placeholder!: boolean;
    @Prop({default: ''}) value!: boolean;
    @Prop({default: ''}) label!: boolean;
    @Prop({default: () => []}) data!: Array<any>;
    @Prop({default: 'Chưa có giá trị'}) noValueText!: any;

    @Ref('selectElement') selectElement!: any;

    isEdit = false;
    selectedValue = this.value;

    @Emit('acceptChange')
    onAcceptChange() {
        this.isEdit = false;
        return this.selectedValue;
    }

    @Emit('cancel')
    onCancel() {
        this.isEdit = false;
        return;
    }

    // get onValueChange(value: string) {
    //     return this.$emit('input', value);
    // }

    get text() {
        const selectedItem = this.data.find((item: any) => {
            return item.id === this.value;
        });
        return selectedItem ? selectedItem.name : this.noValueText;
    }

    edit() {
        if (!this.allowEdit) {
            return;
        }
        this.isEdit = true;
    }
}
