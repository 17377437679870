

































import {Component, Prop, Ref, Vue, Emit, Watch} from "vue-property-decorator";
import {CustomerSuggestionModel} from "@/components/suggestion/customer-suggestion/CustomerSuggestionModel";
import {CustomerService} from "@/services/customer/CustomerService";
import Suggestion from "@/components/suggestion/base-suggestion/Suggestion.vue";
import {
    SuggestionModel,
    SuggestionType
} from "@/components/suggestion/base-suggestion/SuggestionModel";
import {Customer} from "../../../models/customer/Customer";
import {CustomerType} from "@/constants/Customer";

@Component({
    components: {
        Suggestion,
        CustomerQuickInsert: () =>
            import(
                /*webpackChunkName: "CustomerQuickInsert"*/ "@/views/customer/CustomerQuickInsert.vue"
                )
    }
})
export default class CustomerSuggestion extends Vue {
    @Ref("customerQuickInsert") customerQuickInsert!: any;
    @Ref("suggestion") suggestion!: any;
    @Prop({default: SuggestionType.input}) type!: SuggestionType;
    @Prop({default: ""}) placeholder!: string; // popup | input
    @Prop({default: false}) isMultiple!: boolean;
    @Prop({default: false}) showAddButton!: boolean;
    @Prop({default: false}) allowAddWhenNotExists!: boolean;
    @Prop({default: false}) isAddFull!: boolean;
    @Prop({default: true}) isShowSelected!: boolean;
    @Prop({default: 20}) pageSize!: number;
    @Prop({default: null}) customerType!: CustomerType;
    @Prop({default: () => null}) value!: SuggestionModel;
    @Prop({default: () => []}) listSelected!: SuggestionModel<CustomerSuggestionModel>[];

    searchTerm = "";
    listSuggestion: SuggestionModel[] = [];
    isLoading = false;
    totalPage = 0;
    private customerService = new CustomerService();

    get notFoundText() {
        const text = this.$t("noCustomerFounded", [this.searchTerm]);
        return text;
    }

    @Emit("select")
    emitSelectCustomer(suggestion: SuggestionModel<CustomerSuggestionModel>) {
        return suggestion;
    }

    mounted() {
    }

    get addLabel() {
        const add = this.$t('Add');
        const customer = this.$t('Customer');
        return `${add} ${customer.toString().toLowerCase()}: ${this.searchTerm}`;
    }

    onSearch(searchTerm: string) {
        console.log('on searched', searchTerm);
        this.searchTerm = searchTerm;
        this.search();
    }

    onShow(isShow: boolean) {
        if (isShow && this.listSuggestion.length === 0) {
            this.search();
        }
    }

    onCustomerSaved(customer: Customer) {
        if (customer) {
            const customerSuggestion = new CustomerSuggestionModel(customer.id, customer.name);
            customerSuggestion.phoneNumber = customer.phoneNumber;
            customerSuggestion.email = customer.email;
            const suggestion = new SuggestionModel(
                customer.id,
                customer.code + ' - ' + customer.name,
                customer.email,
                customer.image,
                this.checkIsSelected(customer.id),
                customerSuggestion,
                customer.erpCode
            );
            this.emitSelectCustomer(suggestion);
            this.suggestion.show();
            this.listSelected.push(suggestion);
        }
    }

    show() {
        this.suggestion.show();
    }

    async onAdd(searchTerm: string) {
        if (this.isAddFull) {
            this.suggestion.show(false);
            this.customerQuickInsert.add(searchTerm);
        } else {
            const result = await this.customerService.quickInsert(searchTerm);
            if (result.code > 0) {
                this.search();
            }
        }
    }

    private async search(currentPage: number = 1) {
        this.isLoading = true;
        try {
            const result = await this.customerService.suggestion(
                this.searchTerm, this.customerType, currentPage, this.pageSize
            );
            console.log(result);
            this.isLoading = false;
            this.totalPage = Math.ceil(result.totalRows / this.pageSize);
            this.listSuggestion = result.items.map(
                (customerSuggestion: CustomerSuggestionModel) =>
                    new SuggestionModel(
                        customerSuggestion.id,
                        customerSuggestion.code + ' - ' + customerSuggestion.name,
                        customerSuggestion.phoneNumber,
                        customerSuggestion.image,
                        this.checkIsSelected(customerSuggestion.id),
                        customerSuggestion,
                        customerSuggestion.erpCode,
                    )
            );
        } catch (error) {
            this.listSuggestion = [];
        }
    }

    private checkIsSelected(id: string): boolean {
        const index = this.listSelected.findIndex(
            (item: SuggestionModel) => {
                return item.id === id;
            }
        );
        return index !== -1;
    }
}
