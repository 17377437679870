




















import {Component, Emit, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

@Component({
    components: {
        flatPickr
    }
})
export default class TextDateTimeEditable extends Vue {
    @Prop({
        default: () => {
            return {
                enableTime: true,
                dateFormat: 'd-m-Y H:i'
            };
        }
    })
    configDateTimePicker!: any;
    @Prop({default: true}) allowEdit!: boolean;
    @Prop({default: ''}) value!: any;
    @Prop({default: ''}) placeholder!: any;
    @Prop({default: 'Chưa có giá trị'}) noValueText!: any;
    @Ref('inputElement') inputElement!: any;
    @Ref('flatDatetimePickerComponent') flatDatetimePickerComponent!: any;

    isEdit = false;
    selectedDate = null;

    @Watch('isEdit')
    onIsEditChange(value: boolean) {
        if (value) {
            setTimeout(() => {
                this.selectedDate = null;
                this.flatDatetimePickerComponent.$el.focus();
            }, 100);
        }
    }

    @Emit('blur')
    onInputBlur(value: string) {
        return value;
    }

    @Emit('change')
    onDateChange(currentValue: string) {
        this.isEdit = false;
        return currentValue;
    }

    @Emit('acceptChange')
    onAcceptChange() {
        // return this.currentValue;
        this.isEdit = false;
        this.$emit('input', this.selectedDate);
        return this.selectedDate;
    }

    // get currentValue() {
    //     return this.value;
    // }

    // set currentValue(value: any) {
    //     this.$emit('input', value);
    // }

    onEnter() {
        //console.log('on enter');
    }

    onBlur(e: any) {
        const targetValue = e.target.value;
        this.isEdit = false;
        this.onInputBlur(targetValue);
    }

    onChangeDate(value: any) {
        this.selectedDate = value;
    }

    edit() {
        if (!this.allowEdit) {
            return;
        }
        this.isEdit = true;
    }
}
