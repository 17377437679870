



















import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

@Component({
    components: {
        Treeselect
    }
})
export default class TextTreeSelectEditable extends Vue {
    @Prop() value!: any;
    @Prop({default: ''}) text!: any;
    @Prop({default: () => []}) data!: any;
    @Prop({default: false}) isMultiple!: boolean;
    @Prop({default: true}) allowEdit!: boolean;
    @Prop({default: 'Chưa có giá trị'}) noValueText!: any;

    isEdit = false;
    selectedNode: any;
    selectedValue = [];
    currentValue = [];

    @Watch('isEdit')
    onEdit(value: boolean) {
        this.currentValue = this.value;
        if (value) {
            this.$emit('onEdit');
        }
    }

    @Emit('value')
    emitValueChange(value: boolean) {
        this.$emit('input', value);
    }

    @Emit('cancel')
    emitCancel() {
        this.isEdit = false;
        return;
    }

    get displayText() {
        return this.text ? this.text : this.noValueText;
    }

    onTreeSelect(node: any, instanceId: string) {
        this.selectedNode = node;
    }

    onAcceptChange() {
        this.isEdit = false;
        this.$emit('input', this.selectedValue);
        this.$emit('acceptChange', this.selectedNode);
    }

    edit() {
        if (!this.allowEdit) {
            return;
        }
        this.isEdit = true;
    }

    updateValue(value: any) {
        this.selectedValue = value;
    }
}
