import {CustomerAddress} from './CustomerAddress';
import {Share} from '@/models/share/Share';
import {Deal} from '@/models/deal/deal';
import {Social} from '@/models/social/social';
import {BankAccount} from "@/models/bank/BankAccount";
import {CustomerStatus, CustomerType} from "@/constants/Customer";
import {CustomerContact} from "@/models/customer/CustomerContact";

export class CustomerDetailResult {
    id: string;
    name: string;
    code: string;
    shortName: string;
    website: string;
    taxCode: string;
    foundingDate: string | null;
    concurrencyStamp: string;
    createTime: string;
    managerId: string;
    managerFullName: string;
    bciCode: string;
    creatorId: string;
    creatorFullName: string;
    representative: string;
    representativeTitle: string;
    representativeOffice: string;
    description: string;
    status: CustomerStatus;
    isActive: boolean;
    image: string;
    resource: string;
    resourceId: string;
    resourceName: string;
    classifyId: string;
    classifyName: string;
    potentialLevelId: string;
    potentialLevelName: string;
    clarificationId: string;
    clarificationName: string;
    strategyId: string;
    strategyName: string;
    focusGroupId: string;
    focusGroupName: string;
    companySizeId: string;
    companySizeName: string;
    revenueId: string;
    revenueName: string;
    businessCareerId: string;
    businessCareerName: string;
    revenue: number;
    agencyId: string;
    agencyName: string;
    decentralizedId: string;
    salesChannelId: string;
    salesChannelName: string;
    standardLivingId: string;
    standardLivingName: string;
    email: string;
    phoneNumber: string;
    isApproved: boolean;
    roleId: string;
    roleName: string;
    type: CustomerType;
    contacts: CustomerContact[];
    share: Share[];
    deals: Deal[];
    addresses: CustomerAddress[];
    banks: BankAccount[];
    socials: Social[];
    regionId: string;
    regionName: string;
    idNumber: number;
    isEdit: boolean;
    erpCode: string;
    gender: number;

    get genderText() {
        return this.gender === 0 ? 'Nữ'
            : this.gender === 1 ? 'Nam'
                : 'Khác';
    }

    constructor() {
        this.id = '';
        this.name = '';
        this.code = '';
        this.shortName = '';
        this.website = '';
        this.taxCode = '';
        this.foundingDate = '';
        this.concurrencyStamp = '';
        this.createTime = '';
        this.managerId = '';
        this.managerFullName = '';
        this.bciCode = '';
        this.creatorId = '';
        this.creatorFullName = '';
        this.representative = '';
        this.representativeTitle = '';
        this.representativeOffice = '';
        this.description = '';
        this.status = CustomerStatus.Official;
        this.isActive = true;
        this.image = '';
        this.resource = '';
        this.resourceId = '';
        this.isApproved = false;
        this.contacts = [];
        this.share = [];
        this.deals = [];
        this.addresses = [];
        this.banks = [];
        this.socials = [];
        this.idNumber = 0;
        this.isEdit = false;
    }
}
