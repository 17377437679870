import { render, staticRenderFns } from "./CustomerSocialList.vue?vue&type=template&id=7901840e&"
import script from "./CustomerSocialList.vue?vue&type=script&lang=ts&"
export * from "./CustomerSocialList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../locale.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5Cworks%5Cprojects%5Cinka_crm_vue%5Csrc%5Cviews%5Ccustomer%5Csocial%5CCustomerSocialList.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports