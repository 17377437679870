import axios from 'axios';
import {ActionResult} from '@/models/ActionResult';
import {Suggestion} from '@/models/Suggestion';
import {SearchResult} from '@/models/SearchResult';
import {BankAccount} from '@/models/bank/BankAccount';

export default class BankService {
    private readonly url: string;

    constructor() {
        this.url = `${process.env.VUE_APP_GATEWAY_URL}customer/api/v1/banks`;
    }

    findByAccountNumber(accountNumber: string): Promise<ActionResult<BankAccount>> {
        return axios.get(`${this.url}/accounts`, {
            params: {
                accountNumber: accountNumber
            }
        });
    }

    getAllBanks(): Promise<SearchResult<Suggestion>> {
        return axios.get(this.url);
    }

    insert(bank: BankAccount): Promise<ActionResult<string>> {
        return axios.post(this.url, bank);
    }

    update(bank: BankAccount): Promise<ActionResult> {
        return axios.put(`${this.url}/${bank.bankId}`, bank);
    }

    getBranchByBankId(bankId: string): Promise<SearchResult<Suggestion>> {
        return axios.get(`${this.url}/${bankId}/branches`);
    }

    insertAccount(bankId: string, bankAccount: BankAccount): Promise<ActionResult<BankAccount>> {
        return axios.post(`${this.url}/${bankId}/accounts`, bankAccount);

    }

    updateBankAccount(bankAccount: BankAccount): Promise<ActionResult<string>> {
        return axios.put(`${this.url}/accounts/${bankAccount.id}`, bankAccount);
    }

    quickInsertBank(name: string): Promise<ActionResult> {
        return axios.post(`${this.url}/quick`, {
            name
        });
    }

    quickInsertBranch(bankId: string, name: string): Promise<ActionResult> {
        return axios.post(`${this.url}/${bankId}/branches/quick`, {
            name
        });
    }


    bankSuggestion(searchTerm: string): Promise<SearchResult<Suggestion>> {
        return axios.get(`${this.url}/suggestions`, {
            params: {
                nationalId: '',
                searchTerm
            }
        })
    }

    branchSuggestion(bankId: string, searchTerm: string): Promise<SearchResult<Suggestion>> {
        return axios.get(`${this.url}/${bankId}/branches/suggestions`, {
            params: {
                searchTerm
            }
        })
    }
}
