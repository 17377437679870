import { render, staticRenderFns } from "./TextSelectEditable.vue?vue&type=template&id=02ca8f80&scoped=true&"
import script from "./TextSelectEditable.vue?vue&type=script&lang=ts&"
export * from "./TextSelectEditable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02ca8f80",
  null
  
)

export default component.exports