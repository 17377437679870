




































































import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    }
})
export default class CustomerSocialList extends Vue {
    isUpdate = false;
    socialName = '';
    socialValue = '';
    listItem = [{ id: 0, name: 'Facebook', value: 'http://facebook.com/hoangit21' },
        { id: 1, name: 'Instagram', value: 'http://facebook.com/hoangit21' },
        { id: 2, name: 'Zalo', value: 'http://facebook.com/hoangit21' }
    ];

    get modalTitle() {
        const title = this.$t('social').toString().toLowerCase();
        return this.isUpdate ? this.$t('modalTitle.addNew', { msg: title }) : this.$t('modalTitle.update', { msg: title });
    }

    onModalShown() {
        const socialNameInput = this.$refs.socialNameInput as HTMLFormElement;
        socialNameInput.$el.focus();
    }

    add() {
        // this.$bvModal.show('customerSocialModal');
    }

    save() {

    }
}
